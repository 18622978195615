















































































































import { Vue, Component, Prop } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import { Form, Card, FormItem } from "element-ui";
import FormItemBox from "@/components/sign/FormItemBox.vue";
import signjs from "@/components/sign/index";
import { subProjectTech } from "@/components/sign/index";
@Component({
    components: {
        "el-card": Card,
        "el-form": Form,
        "el-form-item": FormItem,
        "al-form-item": FormItemBox,
    },
})
export default class ProjectTechForm extends Vue {
    @Prop({ type: String, required: true, default: () => ({}) })
    childName!: string; //表单数据

    isshow: boolean = false;
    registItem_class_basic = "regist";
    //表单参数
    get registForm() {
        let opt: object = [
            {
                ref: "technology_name",
                formkey: "technology_name",
                type: "input",
                classname: `${this.registItem_class_basic}-technology_name`,
                label: this.$t("sign.tech_name_label"),
                placeholder: this.$t("sign.tech_name_placeholder"),
            },
            {
                ref: "country_id",
                formkey: "country_id",
                type: "selector",
                classname: `${this.registItem_class_basic}-country_id`,
                label: this.$t("sign.country_label"),
                placeholder: this.$t("sign.country_placeholder"),
                apikey: "getProjectOpt",
                apiResKey: "country",
            },
            {
                ref: "technology_filed",
                formkey: "technology_filed",
                type: "radio",
                classname: `${this.registItem_class_basic}-technology_filed`,
                label: this.$t("sign.tech_field_label"),
                placeholder: this.$t("sign.tech_field_placeholder"),
                apikey: "getProjectOpt",
                apiResKey: "field",
            },
            {
                ref: "filed_self",
                formkey: "filed_self",
                type: "input",
                classname: `${this.registItem_class_basic}-filed_self`,
                label: this.$t("sign.tech_filed_self_label"),
                placeholder: this.$t("sign.tech_filed_self_placeholder"),
            },
            {
                ref: "technology_introduction",
                formkey: "technology_introduction",
                type: "editor",
                classname: `${this.registItem_class_basic}-technology_introduction`,
                label: this.$t("sign.tech_intro_label"),
                placeholder: this.$t("sign.tech_intro_placeholder"),
            },
        ];
        return opt;
    }

    /* 表单数据 */
    registFormData = {
        technology_name: "",
        country_id: 1,
        technology_filed: "1",
        filed_self: "",
        technology_introduction: "",
    };

    get innoForm() {
        let opt: object = [
            {
                ref: "technology_advantage",
                formkey: "technology_advantage",
                type: "editor",
                classname: `${this.registItem_class_basic}-technology_advantage`,
                label: this.$t("sign.tech_advantage_label"),
                placeholder: this.$t("sign.tech_advantage_placeholder"),
            },
            {
                ref: "technology_stage",
                formkey: "technology_stage",
                type: "radio",
                classname: `${this.registItem_class_basic}-technology_stage`,
                label: this.$t("sign.tech_stage_label"),
                placeholder: this.$t("sign.tech_stage_placeholder"),
                apikey: "getProjectOpt",
                apiResKey: "stage",
            },
            {
                ref: "technology_develop",
                formkey: "technology_develop",
                type: "editor",
                classname: `${this.registItem_class_basic}-technology_develop`,
                label: this.$t("sign.tech_develop_label"),
                placeholder: this.$t("sign.tech_develop_placeholder"),
            },
            {
                ref: "technology_sustainability",
                formkey: "technology_sustainability",
                type: "editor",
                classname: `${this.registItem_class_basic}-technology_sustainability`,
                label: this.$t("sign.tech_sustain_label"),
                placeholder: this.$t("sign.tech_sustain_placeholder"),
            },
        ];
        return opt;
    }

    innoFormData: any = {
        technology_advantage: "",
        technology_stage: "1",
        technology_develop: "",
        technology_sustainability: "",
    };

    get BusinessForm() {
        let opt: object = [
            {
                ref: "technology_ip",
                formkey: "technology_ip",
                type: "checkbox",
                classname: `${this.registItem_class_basic}-technology_ip`,
                label: this.$t("sign.tech_ip_label"),
                placeholder: this.$t("sign.tech_ip_placeholder"),
                apikey: "getProjectOpt",
                apiResKey: "iptype",
            },
            {
                ref: "ip_owner",
                formkey: "ip_owner",
                type: "radio",
                classname: `${this.registItem_class_basic}-ip_owner`,
                label: this.$t("sign.tech_ip_owner_label"),
                placeholder: this.$t("sign.tech_ip_owner_placeholder"),
                apikey: "getProjectOpt",
                apiResKey: "ipowner",
            },
            {
                ref: "ip_number",
                formkey: "ip_number",
                type: "input",
                classname: `${this.registItem_class_basic}-ip_number`,
                label: this.$t("sign.tech_ip_number_label"),
                placeholder: this.$t("sign.tech_ip_number_placeholder"),
            },
            {
                ref: "ip_description",
                formkey: "ip_description",
                type: "textarea",
                classname: `${this.registItem_class_basic}-ip_description`,
                label: this.$t("sign.tech_ip_description_label"),
                placeholder: this.$t("sign.tech_ip_description_placeholder"),
            },
            {
                ref: "technology_market",
                formkey: "technology_market",
                type: "editor",
                classname: `${this.registItem_class_basic}-technology_market`,
                label: this.$t("sign.tech_market_label"),
                placeholder: this.$t("sign.tech_market_placeholder"),
            },
            {
                ref: "technology_product",
                formkey: "technology_product",
                type: "radio",
                classname: `${this.registItem_class_basic}-technology_product`,
                label: this.$t("sign.tech_product_label"),
                placeholder: this.$t("sign.tech_product_placeholder"),
                apikey: "getProjectOpt",
                apiResKey: "pattern",
            },
            {
                ref: "product_description",
                formkey: "product_description",
                type: "editor",
                classname: `${this.registItem_class_basic}-product_description`,
                label: this.$t("sign.tech_product_description_label"),
                placeholder: this.$t(
                    "sign.tech_product_description_placeholder"
                ),
            },
            {
                ref: "cooperation_type",
                formkey: "cooperation_type",
                type: "checkbox",
                classname: `${this.registItem_class_basic}-cooperation_type`,
                label: this.$t("sign.cooperation_type_label"),
                placeholder: this.$t("sign.cooperation_type_placeholder"),
                apikey: "getProjectOpt",
                apiResKey: "cooperation",
            },
            {
                ref: "cooperation_description",
                formkey: "cooperation_description",
                type: "editor",
                classname: `${this.registItem_class_basic}-cooperation_description`,
                label: this.$t("sign.cooperation_description_label"),
                placeholder: this.$t(
                    "sign.cooperation_description_placeholder"
                ),
            },
            {
                ref: "land_china",
                formkey: "land_china",
                type: "radio",
                classname: `${this.registItem_class_basic}-land_china`,
                label: this.$t("sign.tech_land_china_label"),
                children: [
                    {
                        text: this.$t("sign.tech_land_china_item_1_label"),
                        opt: {
                            "1":{
                                en: "Yes",
                                zh_CN: "是",
                            },
                            "2":{
                                en: "No",
                                zh_CN: "否",
                            },
                        },
                    },
                    {
                        text: this.$t("sign.tech_land_china_item_2_label"),
                        opt: {
                            "3":{
                                en: "Yes",
                                zh_CN: "是",
                            },
                            "4":{
                                en: "No",
                                zh_CN: "否",
                            },
                        },
                    },
                ],
            },
            {
                ref: "financing",
                formkey: "financing",
                type: "textarea",
                classname: `${this.registItem_class_basic}-financing`,
                label: this.$t("sign.tech_financing_label"),
                placeholder: this.$t("sign.tech_financing_placeholder"),
            },
        ];
        return opt;
    }

    BusinessFormData: any = {
        technology_ip: "",
        ip_owner: "1",
        ip_number: "",
        ip_description: "",
        technology_market: "",
        technology_product: "",
        product_description: "",
        cooperation_type: "",
        cooperation_description: "",
        land_china: "1",
        financing: "",
    };

    /* 注册表单验证规则 */
    get registFormRules() {
        let opt = {
            technology_name: [
                {
                    required: true,
                    message: this.$t("sign.tech_name_rule_notnull"),
                    trigger: "blur",
                },
            ],
            country_id: [
                {
                    required: true,
                    message: this.$t("sign.tech_country_rule_notnull"),
                    trigger: "blur",
                },
            ],
            technology_filed: [
                {
                    required: true,
                    message: this.$t("sign.tech_field_rule_notnull"),
                    trigger: "blur",
                },
            ],
            filed_self: [
                {
                    required: true,
                    message: this.$t("sign.tech_filed_self_rule_notnull"),
                    trigger: "blur",
                },
            ],
            technology_introduction: [
                {
                    required: true,
                    message: this.$t("sign.tech_intro_rule_notnull"),
                    trigger: "change",
                },
            ],
            technology_advantage: [
                {
                    required: true,
                    message: this.$t("sign.tech_advantage_rule_notnull"),
                    trigger: "change",
                },
            ],
            technology_stage: [
                {
                    required: true,
                    message: this.$t("sign.tech_stage_rule_notnull"),
                    trigger: "change",
                },
            ],
            technology_develop: [
                {
                    required: true,
                    message: this.$t("sign.tech_develop_rule_notnull"),
                    trigger: "change",
                },
            ],
            technology_sustainability: [
                {
                    required: true,
                    message: this.$t("sign.tech_sustain_rule_notnull"),
                    trigger: "change",
                },
            ],
            technology_ip: [
                {
                    required: true,
                    message: this.$t("sign.tech_ip_rule_notnull"),
                    trigger: "blur",
                },
            ],
            ip_owner: [
                {
                    required: true,
                    message: this.$t("sign.tech_ip_owner_rule_notnull"),
                    trigger: "blur",
                },
            ],
            ip_number: [
                {
                    required: true,
                    message: this.$t("sign.tech_ip_number_rule_notnull"),
                    trigger: "blur",
                },
            ],
            ip_description: [
                {
                    required: false,
                    message: "知识产权描述不能为空",
                    trigger: "blur",
                },
            ],
            technology_market: [
                {
                    required: true,
                    message: this.$t("sign.tech_market_rule_notnull"),
                    trigger: "blur",
                },
            ],
            technology_product: [
                {
                    required: true,
                    message: this.$t("sign.tech_product_rule_notnull"),
                    trigger: "blur",
                },
            ],
            product_description: [
                {
                    required: true,
                    message: this.$t(
                        "sign.tech_product_description_rule_notnull"
                    ),
                    trigger: "blur",
                },
            ],
            cooperation_type: [
                {
                    required: true,
                    message: this.$t("sign.cooperation_type_rule_notnull"),
                    trigger: "blur",
                },
            ],
            cooperation_description: [
                {
                    required: true,
                    message: this.$t(
                        "sign.cooperation_description_rule_notnull"
                    ),
                    trigger: "blur",
                },
            ],
            land_china: [
                {
                    required: true,
                    message: this.$t("sign.tech_land_china_notnull"),
                    trigger: "blur",
                },
            ],
            financing: "",
        };
        return opt;
    }

    //提交表单
    sub(formName: string, callback: any, isloading?: any) {
        console.log(this.registFormData);
        let valid_1: any, valid_2: any, valid_3: any;
        signjs.validateForm(formName, this.$refs).then((res) => {
            valid_1 = res;
            signjs
                .validateForm(`${this.childName}-innovation`, this.$refs)
                .then((res) => {
                    valid_2 = res;
                    signjs
                        .validateForm(`${this.childName}-business`, this.$refs)
                        .then((res) => {
                            valid_3 = res;
                            if (valid_1 && valid_2 && valid_3) {
                                isloading(); //打开loading遮罩层
                                this.setFormData(this.registFormData).then(
                                    (res: any) => {
                                        subProjectTech(
                                            res,
                                            this.$route.params.pid
                                        ).then((res: any) => {
                                            this.$notify({
                                                title:
                                                    res.status === 0
                                                        ? "成功"
                                                        : "失败",
                                                message: res.msg,
                                                type:
                                                    res.status === 0
                                                        ? "success"
                                                        : "error",
                                            });
                                            if (callback) {
                                                callback(res.status, res.data);
                                            } else {
                                                console.error(
                                                    "Regist:No Callback"
                                                );
                                            }
                                        });
                                    }
                                );
                            }
                        });
                });
        });
    }

    //重置表单数据
    resetForm(formName: string) {
        //@ts-ignore
        signjs.resetForm(formName, this.$refs);
    }

    /* 提交前设置提交数据 */
    async setFormData(data: any) {
        const subdata_1 = JSON.parse(JSON.stringify(data));
        const subdata_2 = JSON.parse(JSON.stringify(this.innoFormData));
        const subdata_3 = JSON.parse(JSON.stringify(this.BusinessFormData));
        const subdata = Object.assign(subdata_1, subdata_2, subdata_3);
        // console.log(subdata);
        return subdata;
    }

    // 表单单项验证
    validItem(formname: string, formItem: string | object, callback: any) {
        signjs
            .validateFormItem(formname, formItem, this.$refs)
            .then((res: any) => {
                callback(res);
            });
    }

    /*
     *  获取表单数据
     *  params有pid则请求表单数据，渲染到表单
     *  验证过pid后才会加载表单
     */
    getformData() {
        if (this.$route.params.pid) {
            this.$axios({
                url: `http://ittn_data.ittn.com.cn/api/project/${this.$route.params.pid}/show/1`,
                method: "get",
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem(
                        "api_token"
                    )}`,
                    lang: this.$i18n.locale,
                },
            }).then((res: any) => {
                this.setDataFirst(res.data.data).then(() => {
                    this.isshow = true;
                });
            });
        } else {
            this.$router.back();
            // this.$router.push({ name: "regsit", params: { step: "1" } });
        }
    }

    /* 页面初始化时，分发服务器返回数据到各个表单 */
    async setDataFirst(data: any) {
        Object.keys(this.registFormData).forEach((key) => {
            //@ts-ignore
            this.registFormData[key] = data[key];
        });

        Object.keys(this.innoFormData).forEach((key) => {
            //@ts-ignore
            this.innoFormData[key] = data[key];
        });

        Object.keys(this.BusinessFormData).forEach((key) => {
            //@ts-ignore
            this.BusinessFormData[key] = data[key];
        });
    }

    test(formname?: string) {
        this.registFormData = {
            technology_name: "technology_name",
            country_id: 1,
            technology_filed: "1",
            filed_self: "filed_self",
            technology_introduction: "technology_introduction",
        };
        this.innoFormData = {
            technology_advantage: "technology_advantage",
            technology_stage: "1",
            technology_develop: "technology_develop",
            technology_sustainability: "technology_sustainability",
        };
        this.BusinessFormData = {
            technology_ip: "3,4",
            ip_owner: "1",
            ip_number: "ip_number",
            ip_description: "ip_description",
            technology_market: "technology_market",
            technology_product: "1",
            product_description: "product_description",
            cooperation_type: "1,2",
            cooperation_description: "cooperation_description",
            land_china: "1",
            financing: "financing",
        };
    }

    mounted() {
        this.getformData();
    }
}
